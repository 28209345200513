var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"my-modal",attrs:{"id":"modal-afiliado","title":_vm.titulo,"no-close-on-backdrop":"","ok-title":"Guardar","cancel-variant":"outline-secondary","cancel-title":"Cerrar","size":"lg"},on:{"cancel":_vm.cerrarModal,"close":_vm.cerrarModal,"ok":function($event){$event.preventDefault();return _vm.validar.apply(null, arguments)}}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-for":"nombres"}},[_c('label',[_vm._v("Nombres "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"nombres","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nombres","state":errors.length > 0 ? false:null,"placeholder":"Nombres"},model:{value:(_vm.nombres),callback:function ($$v) {_vm.nombres=$$v},expression:"nombres"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.nombres),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-for":"apellidos"}},[_c('label',[_vm._v("Apellidos "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"apellidos","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"apellidos","state":errors.length > 0 ? false:null,"placeholder":"Apellidos"},model:{value:(_vm.apellidos),callback:function ($$v) {_vm.apellidos=$$v},expression:"apellidos"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.apellidos),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label-for":"fecha_nacimiento"}},[_c('label',[_vm._v("Fecha de Nacimiento "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"fecha de nacimiento","rules":"required|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fecha_nacimiento","state":errors.length > 0 ? false:null,"type":"date"},model:{value:(_vm.fecha_nacimiento),callback:function ($$v) {_vm.fecha_nacimiento=$$v},expression:"fecha_nacimiento"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.fecha_nacimiento),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label-for":"sexo"}},[_c('label',[_vm._v("Sexo "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"sexo","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"sexo","state":errors.length > 0 ? false:null,"options":_vm.sexoOpciones},model:{value:(_vm.sexo),callback:function ($$v) {_vm.sexo=$$v},expression:"sexo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.sexo),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label-for":"ci"}},[_c('label',[_vm._v("C.I. "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"ci","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ci","state":errors.length > 0 ? false:null,"placeholder":"C.I."},model:{value:(_vm.ci),callback:function ($$v) {_vm.ci=$$v},expression:"ci"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.ci),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label-for":"celular"}},[_c('label',[_vm._v("Celular "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"celular","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"celular","state":errors.length > 0 ? false:null,"placeholder":"Celular"},model:{value:(_vm.celular),callback:function ($$v) {_vm.celular=$$v},expression:"celular"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.celular),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-group',{attrs:{"label-for":"direccion"}},[_c('label',[_vm._v("Dirección "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"direccion","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"direccion","state":errors.length > 0 ? false:null,"placeholder":"Dirección"},model:{value:(_vm.direccion),callback:function ($$v) {_vm.direccion=$$v},expression:"direccion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.direccion),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-for":"correo"}},[_c('label',[_vm._v("Correo "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"correo","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"correo","state":errors.length > 0 ? false:null,"placeholder":"Correo"},model:{value:(_vm.correo),callback:function ($$v) {_vm.correo=$$v},expression:"correo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.correo),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label-for":"departamento"}},[_c('label',[_vm._v("Departamento "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"departamento","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"departamento","state":errors.length > 0 ? false:null,"options":_vm.departamentoOptions},model:{value:(_vm.departamento),callback:function ($$v) {_vm.departamento=$$v},expression:"departamento"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.grado_academico),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-for":"grado_academico"}},[_c('label',[_vm._v("Grado Académico "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"grado académico","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"grado_academico","state":errors.length > 0 ? false:null,"placeholder":"Licenciatura, Técnico Superior"},model:{value:(_vm.grado_academico),callback:function ($$v) {_vm.grado_academico=$$v},expression:"grado_academico"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.grado_academico),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-for":"grado_academico_credencial"}},[_c('label',[_vm._v("Grado Académico Credencial "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"grado académico credencial","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"grado_academico_credencial","state":errors.length > 0 ? false:null,"placeholder":"TÉCNICO SUPERIOR EN TOPOGRAFÍA"},model:{value:(_vm.grado_academico_credencial),callback:function ($$v) {_vm.grado_academico_credencial=$$v},expression:"grado_academico_credencial"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.grado_academico_credencial),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-for":"universidad"}},[_c('label',[_vm._v("Universidad "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"universidad","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"universidad","state":errors.length > 0 ? false:null,"placeholder":"Universidad"},model:{value:(_vm.universidad),callback:function ($$v) {_vm.universidad=$$v},expression:"universidad"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.universidad),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-for":"fecha_diploma_academico"}},[_c('label',[_vm._v("Fecha Diploma Académico")]),_c('validation-provider',{attrs:{"name":"fecha diploma académico","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fecha_diploma_academico","state":errors.length > 0 ? false:null,"type":"date"},model:{value:(_vm.fecha_diploma_academico),callback:function ($$v) {_vm.fecha_diploma_academico=$$v},expression:"fecha_diploma_academico"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.fecha_diploma_academico),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-for":"nro_diploma_academico"}},[_c('label',[_vm._v("Número de Diploma Académico "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"número de diploma académico","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nro_diploma_academico","state":errors.length > 0 ? false:null,"placeholder":"Número de Diploma Académico"},model:{value:(_vm.nro_diploma_academico),callback:function ($$v) {_vm.nro_diploma_academico=$$v},expression:"nro_diploma_academico"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.nro_diploma_academico),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-for":"fecha_titulo_nacional"}},[_c('label',[_vm._v("Fecha Título Nacional")]),_c('b-form-input',{attrs:{"id":"fecha_titulo_nacional","type":"date"},model:{value:(_vm.fecha_titulo_nacional),callback:function ($$v) {_vm.fecha_titulo_nacional=$$v},expression:"fecha_titulo_nacional"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-for":"nro_titulo_nacional"}},[_c('label',[_vm._v("Número de Título Nacional "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"número de título nacional","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nro_titulo_nacional","state":errors.length > 0 ? false:null,"placeholder":"Número de Título Nacional"},model:{value:(_vm.nro_titulo_nacional),callback:function ($$v) {_vm.nro_titulo_nacional=$$v},expression:"nro_titulo_nacional"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.nro_titulo_nacional),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-for":"fecha_afiliacion"}},[_c('label',[_vm._v("Fecha de Afiliación")]),_c('b-form-input',{attrs:{"id":"fecha_afiliacion","type":"date"},model:{value:(_vm.fecha_afiliacion),callback:function ($$v) {_vm.fecha_afiliacion=$$v},expression:"fecha_afiliacion"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-for":"fecha_emision_credencial"}},[_c('label',[_vm._v("Fecha de Emisión de Credencial")]),_c('b-form-input',{attrs:{"id":"fecha_emision_credencial","type":"date"},model:{value:(_vm.fecha_emision_credencial),callback:function ($$v) {_vm.fecha_emision_credencial=$$v},expression:"fecha_emision_credencial"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-for":"fecha_vigencia_credencial"}},[_c('label',[_vm._v("Fecha de Vigencia de Credencial")]),_c('b-form-input',{attrs:{"id":"fecha_vigencia_credencial","type":"date"},model:{value:(_vm.fecha_vigencia_credencial),callback:function ($$v) {_vm.fecha_vigencia_credencial=$$v},expression:"fecha_vigencia_credencial"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-for":"fotografia"}},[_c('label',[_vm._v("Fotografía "),(!_vm.id)?_c('span',{staticClass:"text-danger"},[_vm._v("(*)")]):_vm._e()]),_c('validation-provider',{attrs:{"name":"fotografía","rules":_vm.id ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"id":"fotografia","variant":"outline-primary"},on:{"click":_vm.seleccionarImagenes}},[_vm._v(" Seleccionar Fotografía ")]),_c('b-form-file',{staticClass:"mt-1",attrs:{"accept":"image/*","plain":"","hidden":"","id":"imageUpload"},on:{"change":_vm.subirImagenes},model:{value:(_vm.fotografia),callback:function ($$v) {_vm.fotografia=$$v},expression:"fotografia"}}),_c('br'),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.fotografia),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }