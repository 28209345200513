<template>
    <b-modal
        id="modal-afiliado"
        ref="my-modal"
        :title="titulo"
        no-close-on-backdrop
        ok-title="Guardar"
        cancel-variant="outline-secondary"
        cancel-title="Cerrar"
        @cancel="cerrarModal"
        @close="cerrarModal"
        @ok.prevent="validar"
        size="lg"
    >
     <validation-observer ref="simpleRules">
        <b-form>
            <b-row>
                <!-- nombres -->
                <b-col cols="6">
                    <b-form-group
                        label-for="nombres"
                    >
                    <label >Nombres <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="nombres"
                        rules="required|min:3"
                        >
                        <b-form-input
                            id="nombres"
                            v-model="nombres"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Nombres"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.nombres" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group
                        label-for="apellidos"
                    >
                    <label >Apellidos <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="apellidos"
                        rules="required|min:3"
                        >
                        <b-form-input
                            id="apellidos"
                            v-model="apellidos"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Apellidos"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.apellidos" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>                
                <b-col cols="3">
                    <b-form-group
                        label-for="fecha_nacimiento"
                    >
                    <label >Fecha de Nacimiento <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="fecha de nacimiento"
                        rules="required|min:10"
                        >
                        <b-form-input
                            id="fecha_nacimiento"
                            v-model="fecha_nacimiento"
                            :state="errors.length > 0 ? false:null"
                            type="date"
                        />
                        <!-- :state="(errors.length > 0 || errores.nombre) ? false:null" -->
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.fecha_nacimiento" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="3">
                    <b-form-group
                        label-for="sexo"
                    >
                        <label >Sexo <span class="text-danger">(*)</span></label>
                        <validation-provider
                            #default="{ errors }"
                            name="sexo"
                            rules="required|min:1"
                            >
                            <b-form-select
                                id="sexo"
                                v-model="sexo"
                                :state="errors.length > 0 ? false:null"
                                :options="sexoOpciones"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small v-for="error in errores.sexo" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="3">
                    <b-form-group
                        label-for="ci"
                    >
                    <label >C.I. <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="ci"
                        rules="required|min:4"
                        >
                        <b-form-input
                            id="ci"
                            v-model="ci"
                            :state="errors.length > 0 ? false:null"
                            placeholder="C.I."
                        />
                        <!-- :state="(errors.length > 0 || errores.nombre) ? false:null" -->
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.ci" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="3">
                    <b-form-group
                        label-for="celular"
                    >
                    <label >Celular <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="celular"
                        rules="required|min:4"
                        >
                        <b-form-input
                            id="celular"
                            v-model="celular"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Celular"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.celular" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="5">
                    <b-form-group
                        label-for="direccion"
                    >
                    <label >Dirección <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="direccion"
                        rules="required|min:4"
                        >
                        <b-form-input
                            id="direccion"
                            v-model="direccion"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Dirección"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.direccion" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group
                        label-for="correo"
                    >
                    <label >Correo <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="correo"
                        rules="required|email"
                        >
                        <b-form-input
                            id="correo"
                            v-model="correo"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Correo"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.correo" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="3">
                    <b-form-group
                        label-for="departamento"
                    >
                        <label >Departamento <span class="text-danger">(*)</span></label>
                        <validation-provider
                            #default="{ errors }"
                            name="departamento"
                            rules="required|min:4"
                            >
                            <b-form-select
                                id="departamento"
                                v-model="departamento"
                                :state="errors.length > 0 ? false:null"
                                :options="departamentoOptions"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small v-for="error in errores.grado_academico" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group
                        label-for="grado_academico"
                    >
                    <label >Grado Académico <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="grado académico"
                        rules="required|min:4"
                        >
                        <b-form-input
                            id="grado_academico"
                            v-model="grado_academico"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Licenciatura, Técnico Superior"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.grado_academico" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group
                        label-for="grado_academico_credencial"
                    >
                    <label >Grado Académico Credencial <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="grado académico credencial"
                        rules="required|min:4"
                        >
                        <b-form-input
                            id="grado_academico_credencial"
                            v-model="grado_academico_credencial"
                            :state="errors.length > 0 ? false:null"
                            placeholder="TÉCNICO SUPERIOR EN TOPOGRAFÍA"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.grado_academico_credencial" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group
                        label-for="universidad"
                    >
                    <label >Universidad <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="universidad"
                        rules="required|min:4"
                        >
                        <b-form-input
                            id="universidad"
                            v-model="universidad"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Universidad"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.universidad" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group
                        label-for="fecha_diploma_academico"
                    >
                        <label >Fecha Diploma Académico</label>
                        <validation-provider
                        #default="{ errors }"
                        name="fecha diploma académico"
                        rules="required"
                        >
                        <b-form-input
                            id="fecha_diploma_academico"
                            v-model="fecha_diploma_academico"
                            :state="errors.length > 0 ? false:null"
                            type="date"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.fecha_diploma_academico" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group
                        label-for="nro_diploma_academico"
                    >
                    <label >Número de Diploma Académico <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="número de diploma académico"
                        rules="required|min:4"
                        >
                        <b-form-input
                            id="nro_diploma_academico"
                            v-model="nro_diploma_academico"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Número de Diploma Académico"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.nro_diploma_academico" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group
                        label-for="fecha_titulo_nacional"
                    >
                        <label >Fecha Título Nacional</label>
                        <b-form-input
                            id="fecha_titulo_nacional"
                            v-model="fecha_titulo_nacional"
                            type="date"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group
                        label-for="nro_titulo_nacional"
                    >
                        <label >Número de Título Nacional <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="número de título nacional"
                        rules="required|min:4"
                        >
                        <b-form-input
                            id="nro_titulo_nacional"
                            v-model="nro_titulo_nacional"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Número de Título Nacional"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.nro_titulo_nacional" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group
                        label-for="fecha_afiliacion"
                    >
                        <label >Fecha de Afiliación</label>
                        <b-form-input
                            id="fecha_afiliacion"
                            v-model="fecha_afiliacion"
                            type="date"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group
                        label-for="fecha_emision_credencial"
                    >
                        <label >Fecha de Emisión de Credencial</label>
                        <b-form-input
                            id="fecha_emision_credencial"
                            v-model="fecha_emision_credencial"
                            type="date"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group
                        label-for="fecha_vigencia_credencial"
                    >
                        <label >Fecha de Vigencia de Credencial</label>
                        <b-form-input
                            id="fecha_vigencia_credencial"
                            v-model="fecha_vigencia_credencial"
                            type="date"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group
                        label-for="fotografia"
                    >
                        <label >Fotografía <span v-if="!id" class="text-danger">(*)</span></label>
                        <validation-provider
                            #default="{ errors }"
                            name="fotografía"
                            :rules="id ? '' : 'required'"
                            >
                            <b-button
                                id="fotografia"
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                variant="outline-primary"
                                @click="seleccionarImagenes"
                                >
                                Seleccionar Fotografía
                            </b-button>
                            <b-form-file
                                @change="subirImagenes"
                                v-model="fotografia"
                                accept="image/*"
                                class="mt-1"
                                plain
                                hidden
                                id="imageUpload"
                            />
                            <br>
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small v-for="error in errores.fotografia" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </validation-observer>

    </b-modal>
</template>
<script>
import axiosIns from '@/libs/axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BModal, BButton, VBModal, BAlert, BFormGroup, BFormInput, BRow, BCol, BForm, BFormSelect, BFormFile } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required, email } from '@validations'

export default {
    components: {
        BButton,
        BModal,
        BAlert,
        BFormGroup,
        BFormInput,
        ValidationProvider,
        ValidationObserver,
        BForm,
        BRow,
        BCol,
        BFormSelect,
        BFormFile
    },
    props:[
        'accion','item'
    ],
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    watch: {
        accion: function () {
            this.errores=[]; 
            if (this.accion) {
                this.abrir(this.accion,this.item);
            }
        },
        // nombre: function () { 
        //     this.errores.nombre=[];
        // },        
    },
    data () {
        return {
            titulo: null,
            id: null,

            fotografia: null,
            nombres: null,
            apellidos: null,
            fecha_nacimiento: new Date().toISOString().split('T')[0],
            ci: null,
            sexo: null,
            sexoOpciones: [
                { value: 'M', text: 'Masculino'},
                { value: 'F', text: 'Femenino'}
            ],

            departamento: null,
            departamentoOptions:[
                { value: 'BENI', text: 'BENI' },
                { value: 'CHUQUISACA', text: 'CHUQUISACA' },
                { value: 'COCHABAMBA', text: 'COCHABAMBA' },
                { value: 'LA PAZ', text: 'LA PAZ' },
                { value: 'ORURO', text: 'ORURO' },
                { value: 'POTOSÍ', text: 'POTOSÍ' },
                { value: 'PANDO', text: 'PANDO' },
                { value: 'SANTA CRUZ', text: 'SANTA CRUZ' },
                { value: 'TARIJA', text: 'TARIJA' },
            ],

            grado_academico: null,
            grado_academico_credencial: null,
            universidad: null,
            fecha_diploma_academico: new Date().toISOString().split('T')[0],
            nro_diploma_academico: null,
            fecha_titulo_nacional: new Date().toISOString().split('T')[0],
            nro_titulo_nacional: null,
            fecha_afiliacion: new Date().toISOString().split('T')[0],
            celular: null,
            direccion: null,
            correo: null,
            fecha_emision_credencial: new Date().toISOString().split('T')[0],
            fecha_vigencia_credencial: new Date().toISOString().split('T')[0],
            
            required,
            email,
            submitted: false,
            errores:[],
        }
    },
    methods:{
        validar() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    if (!this.submitted) {
                        this.submitted = true;
                        if(this.id == null){
                            this.registrar();
                        }else{
                            this.actualizar();
                        }
                    }
                }
                else{
                    console.log('error al validar');
                }
            })
        },
        abrir(accion,data=[]){
            switch(accion){
                case "registrar":
                {
                    this.titulo='Registrar Afiliado';                    
                    break;
                }
                case "actualizar":
                {
                    this.titulo='Editar Afiliado';

                    this.id = data['id'];                    
                    this.nombres = data['nombres'];
                    this.apellidos = data['apellidos'];
                    // this.fotografia = data['fotografia'];
                    this.fecha_nacimiento = data['fecha_nacimiento'];
                    this.sexo = data['sexo'];
                    this.ci = data['ci'];
                    this.celular = data['celular'];
                    this.direccion = data['direccion'];
                    this.correo = data['correo'];
                    this.departamento = data['departamento'];
                    this.grado_academico = data['grado_academico'];
                    this.grado_academico_credencial = data['grado_academico_credencial'];
                    this.universidad = data['universidad'];
                    this.fecha_diploma_academico = data['fecha_diploma_academico'];
                    this.nro_diploma_academico = data['nro_diploma_academico'];
                    this.fecha_titulo_nacional = data['fecha_titulo_nacional'];
                    this.nro_titulo_nacional = data['nro_titulo_nacional'];
                    this.fecha_afiliacion = data['fecha_afiliacion'];
                    this.fecha_emision_credencial = data['fecha_emision_credencial'];
                    this.fecha_vigencia_credencial = data['fecha_vigencia_credencial'];
                    
                    break;	
                }
            }
        },
        buscarAfiliado(query) {
            var url= '/admin/buscar-afiliado?q='+query;
            axiosIns
            .get(url)
            .then((res) => {
                this.afiliadosSearch = res.data.map((afiliado) => ({
                ...afiliado,
                nombre_completo: `${afiliado.nombres} ${afiliado.apellidos} (${afiliado.rn})`, // Campo compuesto
                }));
            })
            .catch((err) => {
                console.log(err);
            });
        },
        cerrarModal(){
            this.reset();
            this.$emit('cerrarComponente');
        },
        actualizarLista(item){
            this.$emit('actualizarLista',item);
            this.submitted = false;
        },
        registrar(){
            let formData = new FormData();
            formData.append('nombres', this.nombres);
            formData.append('apellidos', this.apellidos);
            formData.append('fecha_nacimiento', this.fecha_nacimiento);
            formData.append('sexo', this.sexo);
            formData.append('ci', this.ci);
            formData.append('celular', this.celular);
            formData.append('direccion', this.direccion);
            formData.append('correo', this.correo);
            formData.append('departamento', this.departamento);
            formData.append('grado_academico', this.grado_academico);
            formData.append('grado_academico_credencial', this.grado_academico_credencial);
            formData.append('universidad', this.universidad);
            formData.append('fecha_diploma_academico', this.fecha_diploma_academico);
            formData.append('nro_diploma_academico', this.nro_diploma_academico);
            formData.append('fecha_titulo_nacional', this.fecha_titulo_nacional);
            formData.append('nro_titulo_nacional', this.nro_titulo_nacional);
            formData.append('fecha_afiliacion', this.fecha_afiliacion);
            formData.append('fecha_emision_credencial', this.fecha_emision_credencial);
            formData.append('fecha_vigencia_credencial', this.fecha_vigencia_credencial);
            formData.append('fotografia', this.fotografia);

            axiosIns.post('/admin/afiliados', 
                formData, 
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(res => {                
                this.$nextTick(() => {
                    this.$refs['my-modal'].toggle('#toggle-btn')
                })
                this.$bvToast.toast('Afiliado registrado', {
                    title: 'Mensaje',
                    variant:'success',
                    solid: false,
                });
                this.actualizarLista(res.data);
                this.reset();
            })
            .catch(err =>{
                this.submitted = false;
                this.errores=err.response.data.errors;
            });
        },
        actualizar(){
            let formData = new FormData();
            formData.append('nombres', this.nombres);
            formData.append('apellidos', this.apellidos);
            formData.append('fecha_nacimiento', this.fecha_nacimiento);
            formData.append('sexo', this.sexo);
            formData.append('ci', this.ci);
            formData.append('celular', this.celular);
            formData.append('direccion', this.direccion);
            formData.append('correo', this.correo);
            formData.append('departamento', this.departamento);
            formData.append('grado_academico', this.grado_academico);
            formData.append('grado_academico_credencial', this.grado_academico_credencial);
            formData.append('universidad', this.universidad);
            formData.append('fecha_diploma_academico', this.fecha_diploma_academico);
            formData.append('nro_diploma_academico', this.nro_diploma_academico);
            formData.append('fecha_titulo_nacional', this.fecha_titulo_nacional);
            formData.append('nro_titulo_nacional', this.nro_titulo_nacional);
            formData.append('fecha_afiliacion', this.fecha_afiliacion);
            formData.append('fecha_emision_credencial', this.fecha_emision_credencial);
            formData.append('fecha_vigencia_credencial', this.fecha_vigencia_credencial);
            formData.append('fotografia', this.fotografia);

            axiosIns.post('/admin/afiliados/editar/' + this.id, 
                formData, 
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            .then(res => {
                this.$nextTick(() => {
                    this.$refs['my-modal'].toggle('#toggle-btn')
                });
                this.$bvToast.toast('Afiliado actualizado', {
                    title: 'Mensaje',
                    variant:'success',
                    solid: false,
                });
                this.actualizarLista();
                this.reset();
            })
            .catch(err =>{
                this.submitted = false;
                this.errores=err.response.data.errors;
            });
        }, 
                            
        seleccionarImagenes() {
            document.getElementById("imageUpload").click();
        },
        async subirImagenes(event) {
            console.log(event);    
        },               
        
        reset(){
            this.id = null;
            
            this.nombres = null;
            this.apellidos = null;
            this.fotografia = null;
            this.fecha_nacimiento = new Date().toISOString().split('T')[0];
            this.sexo = null;
            this.ci = null;
            this.celular = null;
            this.direccion = null;
            this.correo = null;
            this.departamento = null;
            this.grado_academico = null;
            this.grado_academico_credencial = null;
            this.universidad = null;
            this.fecha_diploma_academico = new Date().toISOString().split('T')[0];
            this.nro_diploma_academico = null;
            this.fecha_titulo_nacional = new Date().toISOString().split('T')[0];
            this.nro_titulo_nacional = null;
            this.fecha_afiliacion = new Date().toISOString().split('T')[0];
            this.fecha_emision_credencial = new Date().toISOString().split('T')[0];
            this.fecha_vigencia_credencial = new Date().toISOString().split('T')[0];
            this.fotografia = null;

            this.errores = [];
        }
    }
}
</script>