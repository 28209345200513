<template>
    <b-modal
        id="modal-credencial"
        ref="modal-credencial"
        title="Credencial"
        no-close-on-backdrop
        ok-title="Aceptar"
        cancel-variant="outline-secondary"
        @close="cerrarModal"
        scrollable
        size="lg"
        :hide-footer="true"
    >
        <iframe
            v-if="pdfBase64"
            class="pdf-viewer"
            :src="'data:application/pdf;base64,' + pdfBase64"
            frameborder="0"
            height="500"
            width="100%"
        ></iframe>
    </b-modal>
</template>
<script>
import axiosIns from "@/libs/axios";

import { BModal, BButton, VBModal, BFormGroup, BRow, BCol, BForm, BImg } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    name: 'VisorCertificado',
    components: {
        BButton,
        BModal,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BImg
    },
    props:{
        afiliado: null,
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    watch: {
        afiliado : function(){
            if(this.afiliado)
                this.verCredencial();
        },
    },
    mounted(){
        
    },
    created(){
        
    },
    data () {
        return {
            show: false,
            titulo: 'Recibo',
            submitted: false,
            pdfBase64: null,
            errores:[],
        }
    },
    methods:{
        verCredencial() {
            const url= '/admin/afiliados/credencial/'+this.afiliado.id;
            axiosIns
                .get(url)
                .then((res) => {                
                    this.pdfBase64 = res.data.pdf;
                })
                .catch((err) => {
                console.log(err);
                });
        },

        cerrarModal(){
            this.pdfBase64 = null;
            this.$emit('cerrarComponente');
        },
    }
}
</script>
<style>
    .pdf-viewer{
        height: 70vh;
    }
</style>